// video event tracking for ga/gtm
function sendVideoEvent(action, element) {

    var videoTitle = jQuery(element).data('title');

    ga('send', {
        hitType: 'event',
        eventCategory: 'Video',
        eventAction: action,
        eventLabel: videoTitle + ': ' + element.currentSrc,
        nonInteraction: false
    });

}

jQuery(document).ready(function($) {
    if (window.location.pathname.includes("thank-you-nxg")) {
        $('#footer-thank-you-nxg-modal').css('opacity', '0');
        $('#footer-thank-you-nxg-modal').foundation('open');

        setTimeout(function() {
            $('#footer-thank-you-nxg-modal').css('opacity', '1');
        }, 100);

        setTimeout(function() {
            $(".loader-container > div:nth-child(3)").text("Almost there… making sure everything is just right!");
        }, 3000);

        $(window).on("load", function() {
            setTimeout(function() {
                $('#footer-thank-you-nxg-modal').foundation('close');
            }, 1000);
        });
    }
});
